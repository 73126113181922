import dxToast from 'devextreme/ui/toast';
import dxPopup from 'devextreme/ui/popup';
import portalConfig from '../portalConfig';
import { getCookie } from '../utils/cookie';
import { DATA_SET_METHODS } from '@/utils/const';
import { NEED_LOGIN_CODES, RIGHTS_NO_SERVICE_RIGHT, NO_TOKEN } from '../api/jsonRpcCodes';
import store from '@/store';

const WARNING = 'Во время выполнения запроса возникла ошибка.';
const WARNING_TYPE = ['warning', 'error'];
const WARNING_WIDTH = 'auto';

export function apiCall(url, options = {}) {
  const fetchOptions = {
    credentials: 'include'
  };

  Object.assign(fetchOptions, options);
  if (!fetchOptions.headers) {
    fetchOptions.headers = {};
  }

  const token = localStorage.getItem('token');
  if (token) {
    fetchOptions.headers.Authorization = `Bearer ${token}`;
  }

  return fetch(url, fetchOptions).then((response) => {
    if (!response.ok) {
      throw response;
    }
    return response.json();
  });
}

export function addressSuggest(address) {
  if (!address) {
    return Promise.resolve([]);
  }

  const param = {
    address,
    _config_dataset: 'BASE.DSADDRESSSUGGEST'
  };

  return jsonRPC('getData', param).then((data) => {
    return data.map((item) => item.address);
  });
}

export function jsonRPC(...args) {
  const startDate = new Date();
  const isTokenInvalid = store.getters['session/isTokenInvalid'];
  if (isTokenInvalid && !['NULL.NULL.CREATESESSION'].includes(args[1])) {
    return Promise.reject({ message: 'Token invalid', code: NO_TOKEN });
  }
  //Костыль для сервисов работы с данными, чтобы не менять много кода вызова jsonRPC
  const isDataset = DATA_SET_METHODS.includes(args[0]);

  //Получение точного адреса сервиса для js-api
  let url = isDataset ? process.env.VUE_APP_DATASET_SERVICE : args[0];

  const params = isDataset ? args[1] : args[2];

  //Костыль для датасета
  let dataSetName = '';
  if (Array.isArray(params)) {
    dataSetName = params[0]._config_dataset || params._config_dataset;
    if (params._config_dataset) {
      delete params._config_dataset;
    }
  } else {
    dataSetName = params._config_dataset;
  }
  // Параметр скрывающий сообщение об ошибке
  const silently = params._silently;
  if (params._silently) {
    delete params._silently;
  }

  const jsonRPCMethod = isDataset ? `${dataSetName}.${args[0].toUpperCase()}` : args[1];
  const abortController = isDataset ? args[2] : args[3];

  let fetchOptions = {
    mode: 'cors',
    //credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Accept: 'application/json'
    },
    body: JSON.stringify(
      {
        jsonrpc: '2.0',
        method: jsonRPCMethod,
        params,
        id: 1 //Доработать пакетные запросы
      },
      replacer
    )
  };

  if (portalConfig.apiKey) {
    url = `${url}?apiKey=${portalConfig.apiKey}`;
  } else {
    const token = getCookie('token');
    if (token) {
      fetchOptions.headers.Authorization = `${token}`;
    }
  }

  if (abortController) {
    fetchOptions.signal = abortController.signal;
  }
  //Вызов перехватчика. Это нужно для мобильного приложения
  if (window.interception && window.interception.customAjax) {
    interception.customAjax(fetchOptions.method, fetchOptions.body);
  }

  return fetch(url, fetchOptions)
    .then((response) => {
      if (!response.ok) {
        const message = `${WARNING} ${response.status}: ${response.statusText}`;
        showErrorMessage(response, message, WARNING_TYPE[1]);
        window.console.error(response);
        throw response;
      }
      return response.json();
    })
    .then((responseData) => {
      //Не проверять на токен устаревшие запросы
      const authDate = store.getters['session/getAuthDate'];
      if (!authDate || startDate > authDate) {
        const isTokenInvalid = responseData.error && NEED_LOGIN_CODES.includes(responseData.error.code);
        handleTokenInvalid(isTokenInvalid);
      }

      if (responseData.error) {
        const isRedirect = responseData.error.code === RIGHTS_NO_SERVICE_RIGHT && store.getters['session/userIsGuest'];
        if (!silently && !NEED_LOGIN_CODES.includes(responseData.error.code) && !isRedirect) {
          //Вывод сообщения об ошибке для тех случаев, которые не касаются истекшей сессии и не производят редирект
          const message = responseData.error.message || 'Ошибка выполнения запроса';
          showErrorMessage(responseData.error, message, WARNING_TYPE[0]);
        }

        window.console.error(responseData.error);
        throw responseData.error;
      }

      //Костыль SQLERRM
      if (!silently && responseData.result && responseData.result[0] && responseData.result[0].SQLERRM) {
        showErrorMessage(responseData.result[0], responseData.result[0].SQLERRM, WARNING_TYPE[0]);
        throw responseData.result[0];
      }

      //Костыль для (список страниц) CONNECTIONS.DSPLACETREE
      if (jsonRPCMethod === 'CONNECTIONS.DSPLACETREE.GETDATA') {
        return JSON.parse(responseData.result[0].menu);
      }

      return responseData.result;
    });
}

export function postJson(url, data) {
  const params = {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Accept: 'application/json'
    },
    body: JSON.stringify(data, replacer)
  };
  return fetch(url, params).then((response) => {
    if (!response.ok) {
      const message = `${WARNING} ${response.status}: ${response.statusText}`;
      showErrorMessage(response, message, WARNING_TYPE[1]);
      window.console.error(response);
      throw response;
    }
    return response.json();
  });
}

export function exportData(url, dataSetName, params, method = 'EXPORT') {
  const body = {
    jsonrpc: '2.0',
    method: dataSetName ? `${dataSetName}.${method}` : `NULL.NULL.${method}`,
    params,
    id: 1 //Доработать пакетные запросы
  };

  return postJson(url, body).then((responseData) => {
    if (responseData.error) {
      const message = responseData.error.message || 'Ошибка выполнения запроса';
      showErrorMessage(responseData.error, message, WARNING_TYPE[0]);
      window.console.error(responseData.error);
      throw responseData.error;
    }
    return responseData.result;
  });
}

function handleTokenInvalid(isTokenInvalid) {
  if (store.getters['session/userIsGuest'] && isTokenInvalid) {
    //Создание гостевой сессии
    const guestUserLogin = process.env.VUE_APP_GUEST_USER_LOGIN;
    const guestUserPassword = process.env.VUE_APP_GUEST_USER_PASSWORD;
    if (guestUserLogin && guestUserPassword) {
      store
        .dispatch('session/create', {
          isGuest: true,
          _config_login: guestUserLogin,
          _config_password: guestUserPassword
        })
        .then(() => {
          //Сессия создана, запросы разрешаются
          store.commit('session/setIsTokenInvalid', { isInvalid: false });
          store.dispatch('session/getUserData');
        });
    }
  } else if (isTokenInvalid) {
    //Для не гостя показываем попап логина
    store.commit('session/setShowLoginPopup', { show: true });
  }
  //запросы блокируются, в том числе на время создания сессии
  store.commit('session/setIsTokenInvalid', { isInvalid: isTokenInvalid });
  if (!isTokenInvalid) {
    store.commit('session/setShowLoginPopup', { show: false });
  }
}

//Удаление временной зоны из даты
function replacer(key, value) {
  if (this[key] instanceof Date) {
    return new Date(Date.parse(this[key]) - new Date().getTimezoneOffset() * 60000).toISOString();
  }

  return value;
}

//Вывод сообщений об ошибках
function showErrorMessage(errorData, toastMessage, warningType) {
  let errorPopupIsOpened = false;

  // Создаем контейнер для компонентов вывода сообщений об ошибках
  const $jsonRPCPopups = document.createElement('div');
  $jsonRPCPopups.setAttribute('class', 'server-operation-popups');
  document.body.prepend($jsonRPCPopups);

  // Создаем контейнер для popup-окна с подробным сообщением об ошибках
  const $errorPopup = document.createElement('div');
  $errorPopup.setAttribute('id', 'errorPopup');
  $jsonRPCPopups.prepend($errorPopup);
  const errorPopup = new dxPopup($errorPopup, {
    showTitle: true,
    showCloseButton: false,
    shading: true,
    title: 'Ошибка',
    width: 600,
    height: 'auto',
    toolbarItems: [
      {
        toolbar: 'bottom',
        location: 'center',
        widget: 'dxButton',
        options: {
          text: 'Закрыть',
          width: '100px',
          onClick: () => {
            errorPopup.hide();
            errorPopup.dispose();
            $jsonRPCPopups.remove();
          }
        }
      }
    ]
  });

  // Данные, отображаемые в окне
  let popupErrorMessage = '';
  for (let key in errorData) {
    popupErrorMessage += `<div><b>${key}:</b> ${errorData[key]}</div>`;
  }
  const userData = store.getters['session/userData'];
  if (userData) {
    ['userName', 'userId', 'portalId'].forEach((key) => {
      popupErrorMessage += `<div><b>${key}:</b> ${userData[key]}</div>`;
    });
  }

  const errorMessageDiv = document.createElement('div');
  errorMessageDiv.innerHTML = popupErrorMessage;
  $errorPopup.querySelector('.dx-popup-content').append(errorMessageDiv);

  // Создаем контейнер для компонета toast-оповещения с кратким сообщением об ошибках
  const $jsonRPCToast = document.createElement('div');
  $jsonRPCToast.setAttribute('id', 'jsonRPCToast');
  $jsonRPCPopups.prepend($jsonRPCToast);
  const jsonRPCToast = new dxToast($jsonRPCToast, {
    contentTemplate: (element) => {
      const messageDiv = document.createElement('div');
      messageDiv.innerText = toastMessage;
      const linkDiv = document.createElement('div');
      linkDiv.innerText = 'Подробнее';
      linkDiv.style.cursor = 'pointer';
      linkDiv.style.textDecoration = 'underline';
      linkDiv.style.marginLeft = '3px';
      linkDiv.onclick = () => {
        errorPopupIsOpened = true;
        errorPopup.show();
      };
      element.append(messageDiv);
      element.append(linkDiv);
    },
    displayTime: 3000,
    type: warningType,
    width: WARNING_WIDTH,
    onHidden: () => {
      jsonRPCToast.dispose();
      if (!errorPopupIsOpened) {
        $jsonRPCPopups.remove();
      }
    }
  });

  jsonRPCToast.show();
}